import React, { useEffect, useState } from 'react';
import i18n from 'utils/i18n';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import Card from 'components/molecules/card';
import FormRadioButton from 'components/atoms/form-radio-button';
import FormRadioGroup from 'components/atoms/form-radio-group';
import FormSelect from 'components/atoms/form-select';
import Modal from 'components/molecules/simple-modal';
import Title from 'components/atoms/title';

import { useServiceAppointment, ACTIONS } from 'providers/service-appointment';

import { dateIso2Br } from 'utils/functions';
import { formatPriceAppointment } from 'utils/functions/appointment';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule ServiceAppointmentDateAndPriceStep
 *
 * <!-- TODO: add a description here! -->
 */
function ServiceAppointmentDateAndPriceStep(props) {
  const t = i18n.useTranslations('components.service-appointment-date-and-price-step');
  const { className = '', onNextStep, locale, km = null, hasRecall = 'false' } = props;

  const validationSchema = yup
    .object()
    .shape({
      waitForVehicle: yup.string().oneOf(['true', 'false']),
      choiceDataAndTime: yup.string().oneOf(['true', 'false']),
      newDate: yup.string().notOneOf(['placeholder']),
      newHour: yup.string().notOneOf(['placeholder'])
    })
    .required();

  const {
    register,
    setValue,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      choiceDataAndTime: 'true',
      waitForVehicle: 'true',
      newDate: 'placeholder',
      newHour: 'placeholder'
    }
  });

  const { state, dispatch } = useServiceAppointment();
  const { selectedSlot, slots } = state;
  const today = new Date();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [listOfDates, setListOfDates] = useState([]);
  const [cardsData, setCardsData] = useState([]);
  const [isDateAndHour, setIsDateAndHour] = useState(null);
  const [isPrice, setIsPrice] = useState(null);
  const watchChoiceDataAndTime = watch('choiceDataAndTime');
  const watchNewHour = watch('newHour');
  const waitForVehicle = watch('waitForVehicle');
  const availableChoiceDataAndTime = watchChoiceDataAndTime === 'true';
  const isPriceValid =
    (isPrice !== null && availableChoiceDataAndTime) ||
    (isPrice !== null && !availableChoiceDataAndTime && watchNewHour !== 'placeholder');

  useEffect(() => {
    setValue('waitForVehicle', state.selectedWaitForVehicle);
    organizeSlotsByDate(slots);
    getCardsData();
  }, []);

  useEffect(() => {
    if (!availableChoiceDataAndTime) {
      organizeSlotsByDate(slots);
    }
  }, [watchChoiceDataAndTime]);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const formatDateTime = (obj) => {
    return `${obj.date} ${obj.hour}`;
  };

  const organizeSlotsByDate = (slots) => {
    if (slots?.length === 0) {
      return;
    }
    const slotsByDate = slots?.reduce((acc, slot) => {
      const date = slot.openDate.split(' ')[0];
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(slot);
      return acc;
    }, {});

    const sortedDates = Object.keys(slotsByDate).sort((a, b) => new Date(a) - new Date(b));
    setListOfDates(sortedDates);

    if (selectedSlot) {
      const [closestDate, earliestTime] = selectedSlot.openDate.split(' ');
      setIsDateAndHour({ date: closestDate, hour: earliestTime });
      setValue('newDate', closestDate);
      setIsPrice(formatPriceAppointment(selectedSlot?.price, locale, t('bonus_text')));
    } else {
      const closestDate = sortedDates?.find((date) => new Date(date) >= today) || '';
      if (closestDate) {
        const earliestSlot = slotsByDate[closestDate].sort(
          (slot1, slot2) => new Date(slot1.openDate) - new Date(slot2.openDate)
        )[0];

        const earliestTime = earliestSlot.openDate.split(' ')[1];

        setIsDateAndHour({ date: closestDate, hour: earliestTime });
        setValue('newDate', isDateAndHour?.date);
        setIsPrice(formatPriceAppointment(earliestSlot?.price, locale, t('bonus_text')));
      }
    }
  };

  const getCardsData = () => {
    const newCardsData = [];

    if (hasRecall === 'true') {
      newCardsData.push({
        key: 'recall',
        title: t('recall_service_title'),
        content: t('recall_service_content'),
        variant: 'simple-card',
        isUppercase: false
      });
    }

    if (km) {
      newCardsData.push({
        key: 'mantenimiento',
        title: `${t('maintenance_service_title')} ${km}`,
        content: t('maintenance_service_content'),
        variant: 'simple-card',
        isUppercase: false
      });
    }

    setCardsData(newCardsData);
  };

  const onChangeDate = (e) => {
    setValue('newDate', e.currentTarget.value);
    setIsPrice(null);
    setValue('newHour', 'placeholder');
  };

  const onChangeHour = (e) => {
    setValue('newHour', e.currentTarget.value);
    const selected = slots.find((slot) => slot?.openDate === e.currentTarget.value);
    setIsPrice(formatPriceAppointment(selected?.price, locale, t('bonus_text')));
  };

  const handleConfirmation = () => {
    const formattedDateTime = formatDateTime(isDateAndHour);
    const selectedHour = watch('newHour');
    const selectedDateAndHour = availableChoiceDataAndTime ? formattedDateTime : selectedHour;
    if (selectedDateAndHour) {
      const selectedSlot = slots.find((slot) => slot.openDate === selectedDateAndHour);
      if (selectedSlot) {
        dispatch({ type: ACTIONS.SET_SELECTED_SLOT, payload: selectedSlot });
        dispatch({ type: ACTIONS.SET_SELECTED_WAIT_FOR_VEHICLE, payload: waitForVehicle });
        onNextStep();
      }
    }
  };

  const handleReturnToDealers = () => {
    const prevStepIndex = state.currentStep - 1;

    dispatch({ type: ACTIONS.SET_CURRENT_STEP, payload: prevStepIndex });
  };

  return (
    <div className={`${css['molecule__service-appointment-date-and-price-step-container']} ${className}`}>
      <FormRadioGroup
        className={css['form-container-group-choice-date-time']}
        label={t('label_radio_group_choice_date_and_time')}
        name="choiceDataAndTime"
        dataRegister={register('choiceDataAndTime')}
        dataErrors={errors['choiceDataAndTime']}
        spacingGap="extraSmall"
      >
        <FormRadioButton
          className={css['group-radio-button-date']}
          id="yes"
          label={
            isDateAndHour ? (
              <div className={css['group-radio-button-label']}>
                <span>{`${t('label_select_date')} ${dateIso2Br(isDateAndHour?.date)}`}</span>
                <span style={{ marginLeft: '20px' }}>{`${t('label_select_hour')} ${isDateAndHour?.hour.slice(
                  0,
                  5
                )}`}</span>
              </div>
            ) : (
              ''
            )
          }
          value="true"
        />
        <span className={css['group-radio-separator']}>O</span>

        <FormRadioButton
          className={css['group-radio-button-date']}
          id="no"
          label={t('label_select_another_date')}
          value="false"
        />
      </FormRadioGroup>
      {!availableChoiceDataAndTime && (
        <div className={css['form-container-selected-date-time']}>
          <div className={css['selected-date-time']}>
            <FormSelect
              className={css['input-selected']}
              id="newDate"
              label={t('label_new_date')}
              placeholder={t('placeholder_new_date')}
              onChange={onChangeDate}
              dataRegister={register('newDate')}
              displayError={false}
            >
              {listOfDates?.length > 0 &&
                listOfDates.map((date, index) => {
                  return (
                    <option key={index} value={date}>
                      {dateIso2Br(date)}
                    </option>
                  );
                })}
            </FormSelect>
            <FormSelect
              className={css['input-selected']}
              id="newHour"
              label={t('label_new_hour')}
              placeholder={t('placeholder_new_hour')}
              onChange={onChangeHour}
              dataRegister={register('newHour')}
              displayError={false}
            >
              {slots
                .filter((slot) => slot.openDate.split(' ')[0] === watch('newDate'))
                .map((slot, index) => (
                  <option key={index} value={slot.openDate}>
                    {slot.openDate.split(' ')[1].slice(0, 5)}
                  </option>
                ))}
            </FormSelect>
          </div>
          <div className={css['return-dealers-container']}>
            <span>{t('text_choose_dealers')}</span>
            <AnchorLink
              hasArrow={false}
              className={css['return-dealers-container-link']}
              linkLabel={t('label_choose_dealers')}
              onClick={handleReturnToDealers}
            />
          </div>
        </div>
      )}

      <FormRadioGroup
        className={css['form-container-group-wait-for-vehicle']}
        label={t('label_radio_group_wait_for_vehicle')}
        name="waitForVehicle"
        dataRegister={register('waitForVehicle')}
        dataErrors={errors['waitForVehicle']}
        spacingGap="standard"
      >
        <FormRadioButton
          className={css['group-radio-button-vehicle']}
          id="radio-yes"
          label={t('label_select_wait_yes')}
          value="true"
        />
        <FormRadioButton
          className={css['group-radio-button-vehicle']}
          id="radio-no"
          label={t('label_select_wait_no')}
          value="false"
        />
      </FormRadioGroup>

      <div className={css['requested-services-and-price-container']}>
        {cardsData?.length > 0 && (
          <div className={css['requested-services-container']}>
            <h2 className={css['requested-services-title']}>{t('requested_services_title')}</h2>

            <div className={css['requested-services-info']}>
              {cardsData?.map((cardData) => (
                <div key={cardData?.key}>
                  <Card
                    title={cardData?.title}
                    content={cardData?.content}
                    variant={cardData?.variant}
                    isUppercase={cardData?.isUppercase}
                  />
                </div>
              ))}
            </div>
          </div>
        )}
        <div className={css['requested-price-container']}>
          {isPriceValid && (
            <div className={css['requested-price-info']}>
              <span className={css['title-price-info']}>{t('title_price_info')}</span>
              <span className={css['price-info']}>{isPrice}</span>
            </div>
          )}

          <AnchorLink linkLabel={t('label_legales')} onClick={openModal} />
        </div>
      </div>
      <Button
        className={css['form-container-button']}
        onClick={() => handleConfirmation()}
        disabled={!availableChoiceDataAndTime && watchNewHour === 'placeholder'}
      >
        {t('label_button_confirm')}
      </Button>

      {isModalOpen && (
        <Modal className={css['modal-container']} isOpen={isModalOpen} onClose={closeModal}>
          <Title variant={2}>{t('title_modal_legal_text')}</Title>
          <div className={css['modal-container-content']}>{t('content_modal_legal_text')}</div>
        </Modal>
      )}
    </div>
  );
}

export default ServiceAppointmentDateAndPriceStep;
