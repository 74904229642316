import Service from 'utils/repo/service';
import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

class ServiceOffers implements ContentBlock {
  label = 'Service Offers';

  component = 'ServiceOffers';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [anchor];

  properties = [];

  async adapter (contents: any, extra: any, dataFetcher: any) {
    return {
      serviceItemCategories: (
        await dataFetcher(Service.getServiceItemCategoriesByPage(), { pageId: extra?.context?.page?.id})
      )?.data?.serviceByPage?.serviceItemCategories || [],
      locale: extra?.context?.locale || 'pt-BR'
    };
  }
}

export { ServiceOffers };
