import React, { useEffect, useState } from 'react';
import Section from 'components/utils/section';
import Wrapper from 'components/atoms/wrapper';
import css from './styles.module.scss';

function SecondaryNavigation(props) {
  const { className = '', title, showSectionNavigation = false, links = [], children, ...other } = props;
  const [currentPath, setCurrentPath] = useState('');

  function extractLastSegment(url) {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

  function isCurrentPath(url) {
    return extractLastSegment(currentPath) === extractLastSegment(url);
  }

  function renderIcon(link) {
    const isCurrent = isCurrentPath(link?.url);
    const typeImage =
      link?.activeIcon?.mediaType?.title === 'image' ||
      link?.inactiveIcon?.mediaType?.title === 'image' ||
      link?.activeIcon?.type === 'image' ||
      link?.inactiveIcon?.type === 'image';
    const iconUrl = isCurrent ? link?.activeIcon?.url : link?.inactiveIcon?.url;
    const iconAlt = isCurrent
      ? link?.activeIcon?.title || link?.activeIcon?.alt
      : link?.inactiveIcon?.title || link?.inactiveIcon?.alt;
    if (iconUrl && typeImage) {
      return <img className={css['list-item-image']} src={iconUrl} alt={iconAlt} />;
    } else {
      return null;
    }
  }

  useEffect(() => {
    const path = window.location.pathname;
    setCurrentPath(path);
  }, []);

  return showSectionNavigation && links?.length > 0 ? (
    <Wrapper className={`${css['molecule__secondary-navigation-container']} ${className}`} {...other}>
      <Section className={css['container-nav']} tag="nav">
        <div className={css['container-nav-title']}>
          <p className={css['title']}>{title}</p>
        </div>
        <div className={css['container-nav-list']}>
          {links?.map((link, index) => (
            <a
              key={index}
              className={css['list-item']}
              href={link?.url}
              style={{
                color: isCurrentPath(link?.url) && '#FFFFFF',
                background: isCurrentPath(link?.url) && link?.colorActiveLink,
                pointerEvents: isCurrentPath(link?.url) ? 'none' : undefined
              }}
            >
              <span className={css[isCurrentPath(link?.url) && renderIcon(link) ? 'link-label-active' : 'link-label']}>
                {link?.label}
              </span>
              {renderIcon(link)}
            </a>
          ))}
        </div>
        {children}
      </Section>
    </Wrapper>
  ) : (
    <div></div>
  );
}
export default SecondaryNavigation;
