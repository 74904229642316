import { buildTemplate } from 'utils/page/templates/functions';
import * as components from 'components/index';
import BTB from 'utils/btb/utils';
import Logger from 'utils/repo/logger';
import React from 'react';

class PageBuilder {
  protected readonly pageData: any;
  protected readonly repo: any;

  constructor(pageData: any, repo: any) {
    this.pageData = pageData;
    this.repo = repo;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public async page(context): Promise<any[]> {
    const templateName = this.pageData?.template || 'PageTemplate';
    const template = buildTemplate(templateName);
    const dataFetcher = async (query, variables = {}) => {
      return await this.repo.get(query, variables);
    };

    let data = [];

    try {
      data = await this.repo.get(template.queryBuilder(), { pageId: this.pageData.id });
    } catch (error) {
      Logger.error('[PageBuilder] Failed to query page data. Reason: ', error);
      throw error;
    }

    context.page = this.pageData;
    if ([
        'VehicleTemplate',
        'ToyotaCycleTemplate',
        'VehicleVerticalTemplate',
        'VehicleHybridFlexTemplate'
      ].includes(templateName)) {
      context.btb = new BTB();
    }

    return await template.build(data, context, dataFetcher);
  }
}

/**
 * Import and instantiate the React Component
 *
 * @param name The class name of wanted Component
 * @param props Properties for component
 */
const buildComponent = (name: string, key: string, props: any, children?: []) => {
  const CustomComponent = components?.[name];

  if (!CustomComponent) {
    throw new Error(`[PageBuilder] Component with name '${name}' not exists`);
  }

  const anchor = props?.anchor || key;

  if (props?.anchor) {
    props = { ...props, style: { ...props.style, scrollMarginTop: '7.5rem' } };
  }

  if (!children || !children?.length) {
    return <CustomComponent id={anchor} key={key} {...props} />;
  }

  const builded = [];

  children.forEach((tuple: any, idx: number) => {
    builded.push(buildComponent(tuple?.component, `${key}-children-${idx}`, tuple?.props, tuple?.children || []));
  });

  return (
    <CustomComponent id={anchor} key={key} {...props}>
      {builded}
    </CustomComponent>
  );
};

export { PageBuilder, buildComponent };
