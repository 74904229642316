import React, { useState, useRef, useEffect } from 'react';
import i18n from 'utils/i18n';

import Media from 'components/molecules/media';
import AnchorLink from 'components/atoms/anchor-link';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

import { verifyIfLinkIsInAnotherDomain } from 'utils/url';

// loading the sass style fot the component
import css from './styles.module.scss';
/**
 * Molecule Card
 *
 * A card component
 */
function Card(props) {
  const {
    className = '', //
    children,
    title,
    content,
    additionalContent,
    linkLabel,
    link,
    links,
    isHorizontal = false,
    isExternal = false,
    isDownload = false,
    bgColor = null,
    color,
    variant,
    media,
    date = null,
    isUppercase = true,
    category = null,
    autoPlay,
    muted,
    loop,
    controls,
    ...other
  } = props;

  const [dataHidden, setDataHidden] = useState(false);
  const [isContentOverflowing, setIsContentOverflowing] = useState(false);
  const contentRef = useRef(null);

  const t = i18n.useTranslations('components.card');

  const isExternalLink = link ? verifyIfLinkIsInAnotherDomain(link) : false;
  const isOnlyOneLink = links?.length === 1;

  function onDataHidden() {
    setDataHidden(!dataHidden);
  }

  useEffect((_) => {
    if (!isHorizontal) {
      document.fonts?.ready.then(function () {
        const ref = contentRef.current;

        if (ref?.scrollHeight > ref?.offsetHeight) {
          setIsContentOverflowing(true);
        }
      });
    }
  }, []);

  return (
    <div
      className={`${css['molecule__card-container']} ${className}`}
      {...other}
      data-is-horizontal={isHorizontal ? true : false}
      data-color={color}
      data-variant={variant}
      data-bg-color={bgColor}
    >
      {media && (
        <div className={css['card__media']}>
          {isOnlyOneLink && link ? (
            <a href={link} className={css['card__media-link']} target={isExternalLink ? '_blank' : null}>
              <Media
                url={media?.url}
                alt={media?.alt}
                type={media?.type}
                autoPlay={autoPlay}
                muted={muted}
                loop={loop}
                controls={controls}
              />
            </a>
          ) : (
            <Media
              url={media?.url}
              alt={media?.alt}
              type={media?.type}
              autoPlay={autoPlay}
              muted={muted}
              loop={loop}
              controls={controls}
            />
          )}
        </div>
      )}
      <div className={css['card__content-wrapper']}>
        <div className={css['card__content-container']} data-is-uppercase={isUppercase}>
          {date && <div className={css['card__date']}>{date}</div>}
          {category && (
            <div className={css['card__category']}>
              <p>{category}</p>
            </div>
          )}
          {title ? (
            isOnlyOneLink && link ? (
              <h3 className={css['card__title']}>
                <a href={link} target={isExternalLink ? '_blank' : null}>
                  {title}
                </a>
              </h3>
            ) : (
              <h3 className={css['card__title']}>{title}</h3>
            )
          ) : null}
          {content && (
            <div data-hidden={dataHidden} className={css['card__content']} ref={contentRef}>
              <ReactMarkdown rehypePlugins={[rehypeRaw]}>{content}</ReactMarkdown>
            </div>
          )}
          {isContentOverflowing && (
            <AnchorLink
              className={css['button-view-more']}
              linkLabel={dataHidden ? t('link_label_view_less') : t('link_label_view_more')}
              onClick={onDataHidden}
              onBlur={(_) => setDataHidden(false)}
            />
          )}
          {additionalContent && <p className={css['card__additional-content']}>{additionalContent}</p>}
        </div>
        {link && linkLabel && (
          <AnchorLink
            link={link}
            linkLabel={linkLabel}
            isExternal={isExternal}
            isDownload={isDownload}
            className={css['card__anchor-link']}
          />
        )}
        {links &&
          links.map((data, index) => {
            if (index > 0) {
              if (data.link && data.linkLabel) {
                return (
                  <AnchorLink
                    key={index}
                    link={data.link}
                    linkLabel={data.linkLabel}
                    isExternal={isExternal}
                    isDownload={isDownload}
                    className={css['card__anchor-link']}
                  />
                );
              }
            }
          })}
      </div>
    </div>
  );
}

export default Card;
