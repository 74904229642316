import React, { useEffect, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import i18n from 'utils/i18n';
import css from './styles.module.scss';

import AnchorLink from 'components/atoms/anchor-link';
import FormSelect from 'components/atoms/form-select';
import IconPinMap from 'components/atoms/icon-pin-map';
import Title from 'components/atoms/title';
import BackToTop from 'components/molecules/back-to-top';
import MapInformation from 'components/molecules/map-information';
import InfoDealer from 'components/molecules/info-dealer';

// import InfoDealer from 'components/molecules/info-dealer';

/**
 * Organism MyDealers
 *
 * Find and favorite dealers
 */
function MyDealers(props) {
  const t = i18n.useTranslations('components.my-dealers');

  const { className = '', dealers = [], googleKey, children, locale, ...other } = props;

  const listStates = [...new Set(dealers.map((state) => state.address.state))].sort();

  const [selectedState, setSelectedState] = useState('');
  const [markers, setMarkers] = useState(dealers || []);
  const [favoriteDealers, setFavoriteDealers] = useState([]);
  const [mapCenter, setMapCenter] = useState(null);
  const [goToCurrentPosition, setGoToCurrentPosition] = useState(null);

  const [locationError, setLocationError] = useState(null);

  const handleUseMyLocation = async () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setGoToCurrentPosition({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
          });
        },
        (error) => {
          setLocationError(t('unable_to_access_location'));
          console.log(error);
        }
      );
    } else {
      setLocationError(t('message_geolocation_not_supported'));
    }
  };

  const [citiesFromStates, updateCitiesFromStates] = useReducer(
    (prev, next) => {
      const newEvent = { ...prev, ...next };

      // Ensure that the city is selected just if we have the cities list
      if (prev.citiesList.length > 0) {
        newEvent.city = next.city;
      }

      return newEvent;
    },
    { city: '', citiesList: [] }
  );

  const { register, setValue } = useForm({
    defaultValues: {
      state: 'placeholder',
      city: 'placeholder'
    }
  });

  useEffect(async (_) => await fetchFavoriteDealers(), []); // eslint-disable-next-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadCitiesList();
    updateCitiesFromStates({ city: null });
    setValue('city', 'placeholder');
  }, [selectedState]);

  useEffect(() => {
    loadServicesList();
  }, [citiesFromStates.city]);

  useEffect(() => {
    const updatedMarkers = markers?.map((marker) => ({
      ...marker,
      isFavorite: favoriteDealers.some((favorite) => favorite.id === marker.id)
    }));

    setMarkers(updatedMarkers || []);
  }, [favoriteDealers]);

  async function fetchFavoriteDealers() {
    try {
      const response = await fetch(`/api/favorite-dealers/get-favorite-dealers`);
      const responseData = (await response.json())?.favoriteDealers || [];
      const favoriteDealersIds = responseData.map(({ id }) => id);
      const updatedFavoriteDealers = dealers.filter(({ id }) => favoriteDealersIds.includes(id));

      if (!response.ok) {
        throw new Error(`Failed to fetch favorite dealers: ${response.status} - ${response.statusText}`);
      }

      setFavoriteDealers(updatedFavoriteDealers.map((currentDealer) => ({ ...currentDealer, isFavorite: true })));
    } catch (error) {
      console.log(error);
    }
  }

  async function requestAddFavoriteDealer(dealer) {
    try {
      const url = `/api/favorite-dealers/create-favorite-dealer`;
      const opts = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ dealersIds: [dealer?.id] })
      };
      const response = await fetch(url, opts);
      const data = await response.json();

      if (!response.ok || !data) {
        throw new Error(`Failed to fetch favorite dealers: ${response.status} - ${response.statusText}`);
      }

      setFavoriteDealers([...favoriteDealers, { ...dealer, isFavorite: true }]);
    } catch (error) {
      console.log(error);
    }
  }

  async function requestRemoveFavoriteDealer(dealer) {
    try {
      const url = `/api/favorite-dealers/remove-favorite-dealer`;
      const opts = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ dealersIds: [dealer?.id] })
      };
      const response = await fetch(url, opts);
      const data = await response.json();

      if (!response.ok || !data) {
        throw new Error(`Failed to fetch favorite dealers: ${response.status} - ${response.statusText}`);
      }

      setFavoriteDealers(favoriteDealers.filter((fav) => fav.id !== dealer.id));
    } catch (error) {
      console.log(error);
    }
  }

  function loadCitiesList() {
    const dealersFromState = dealers?.filter(
      (dealer) => dealer?.address?.state.toUpperCase() === selectedState?.toUpperCase()
    );
    const citiesList = [...new Set(dealersFromState.map((dealer) => dealer.address.city.toUpperCase()))].sort();
    updateCitiesFromStates({ citiesList });

    if (selectedState) {
      setMarkers(dealersFromState);
    }
  }

  function loadServicesList() {
    const markersFilteredByCity = [];

    dealers?.forEach((dealer) => {
      if (
        dealer.address.state.toUpperCase() === selectedState &&
        dealer.address.city.toUpperCase() === citiesFromStates.city?.toUpperCase()
      ) {
        markersFilteredByCity.push(dealer);
      }
    });

    if (citiesFromStates.city) {
      setMarkers(markersFilteredByCity);
    }
  }

  function onChangeState(event) {
    setSelectedState(event.target.value);
    setValue('city', 'placeholder');
  }

  function onChangeCity(event) {
    updateCitiesFromStates({ city: event.target.value });
  }

  async function handleFavoriteChange(dealer) {
    const updatedFavoriteState = !dealer.isFavorite;

    if (updatedFavoriteState) {
      requestAddFavoriteDealer(dealer);
    } else {
      requestRemoveFavoriteDealer(dealer);
    }
  }

  function handleInfoDealer(dealer) {
    setMapCenter({ ...dealer?.position, timestamp: Date.now() });
    document.querySelector('[data-id="map"]')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    });
  }

  return (
    <div className={`${css['organism__my-dealers-container']} ${className}`} {...other}>
      <Title level={1} variant={2}>
        {t('title')}
      </Title>
      <p className={css['subtitle']}>{t('subtitle')}</p>
      <div className={css['filter-form']}>
        <FormSelect
          displayLabel={false}
          displayError={false}
          id="selectState"
          placeholder={t('placeholder_state')}
          className={css['select-input']}
          dataRegister={register('state')}
          onChange={onChangeState}
        >
          {listStates?.length > 0 ? (
            listStates?.map((state, index) => {
              return (
                <option key={index} value={state}>
                  {state}
                </option>
              );
            })
          ) : (
            <option>{t('placeholder_state')}</option>
          )}
        </FormSelect>
        <FormSelect
          displayLabel={false}
          displayError={false}
          id="selectCity"
          placeholder={t('placeholder_city')}
          className={css['select-input']}
          dataRegister={register('city')}
          onChange={onChangeCity}
        >
          {citiesFromStates.citiesList.length > 0 ? (
            citiesFromStates.citiesList?.map((city, index) => {
              return (
                <option key={index} value={city}>
                  {city}
                </option>
              );
            })
          ) : (
            <option>{t('placeholder_city')}</option>
          )}
        </FormSelect>
      </div>

      <AnchorLink
        size="small"
        hasArrow={false}
        onClick={handleUseMyLocation}
        linkLabel={
          <>
            {t('use_my_location')} <IconPinMap />
          </>
        }
      />
      <span>{locationError}</span>

      <MapInformation
        data-id={'map'}
        locale={locale}
        markers={markers}
        selectedCity={citiesFromStates.city}
        selectedState={selectedState}
        goToCurrentPosition={goToCurrentPosition}
        selectedMapCenter={mapCenter}
        handleFavoriteChange={handleFavoriteChange}
        centerOffset={-0.012}
      />

      <Title level={2} variant={2}>
        {t('favorite_dealers')}
      </Title>
      <div className={css['favorite-dealers-list']}>
        {favoriteDealers?.length
          ? favoriteDealers.map((dealer, index) => (
              <InfoDealer
                key={dealer?.id + index}
                className={css['info-dealer']}
                dealer={dealer}
                listServices={dealer?.services}
                locale={locale}
                handleFavoriteChange={handleFavoriteChange}
                handleInfoDealer={() => handleInfoDealer(dealer)}
              />
            ))
          : t('has_no_favorite_dealers')}
      </div>

      <BackToTop />
    </div>
  );
}

export default MyDealers;
