import React, { useCallback, useState, useLayoutEffect } from 'react';
import { useRouter } from 'next/router';
import getConfig from 'next/config';
import Script from 'next/script';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import i18n from 'utils/i18n';
import { captchaToken } from 'utils/captcha/token';
import { phoneMask, cpfMask, isValidCPF, chassiMask, phoneMaskTDV } from 'utils/validations';
import { removeSpecialCharactersAndNumbers, removeSpecialCharacters } from 'utils/forms';
import AnchorLink from 'components/atoms/anchor-link';
import Button from 'components/atoms/button';
import ContentDivider from 'components/atoms/content-divider';
import FormCheckbox from 'components/atoms/form-checkbox';
import FormInput from 'components/atoms/form-input';
import FormSelect from 'components/atoms/form-select';
import FormTextarea from 'components/atoms/form-text-area';
import Section from 'components/utils/section';
import SectionHeader from 'components/molecules/section-header';
import SuccessSubmitForm from 'components/molecules/success-submit-form';
import Wrapper from 'components/atoms/wrapper';
import Title from 'components/atoms/title';
// loading the sass style fot the component
import css from './styles.module.scss';

const { publicRuntimeConfig } = getConfig();

/**
 * Molecule ContactForm
 *
 * The services page contact form
 */
function ContactForm(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.contact-form');
  const router = useRouter();

  const {
    className = '',
    locale,
    headerSpacing,
    title,
    description,
    titleSuccess,
    contentSuccess,
    titleError,
    contentError,
    children,
    contactFormType,
    models,
    dealers,
    states,
    ...other
  } = props;

  const isTDB = locale === 'pt-br';
  const isTDV = locale === 'es-ve';
  const [listCities, setListCities] = useState([]);
  const [listDealers, setListDealers] = useState([]);

  const getSchemaConfigByType = () => {
    switch (contactFormType) {
      case 'Presupuesto':
        return {
          model: yup.string().required(t('message_error_required_model_interest')),
          name: yup.string().required(t('message_error_required_name')),
          surname: yup.string().required(t('message_error_required_surname')),
          document: yup
            .string()
            .required(t('message_error_required_document'))
            .min(5, t('message_error_min_char_document')),
          email: yup.string().required(t('message_error_required_email')).email(t('message_error_invalid_email')),
          telephone: yup
            .string()
            .required(t('message_error_required_phone'))
            .min(11, t('message_error_min_char_phone')),
          state: yup.string().required(t('message_error_required_state')),
          city: yup.string().required(t('message_error_required_city')),
          dealerId: yup.string().required(t('message_error_required_dealerId')),
          agreement: yup.boolean().oneOf([true], '')
        };
      case 'Atención al cliente':
        return {
          name: yup.string().required(t('message_error_required_name')),
          surname: yup.string().required(t('message_error_required_surname')),
          document: yup
            .string()
            .required(t('message_error_required_document'))
            .min(5, t('message_error_min_char_document')),
          email: yup.string().required(t('message_error_required_email')).email(t('message_error_invalid_email')),
          telephone: yup
            .string()
            .required(t('message_error_required_phone'))
            .min(11, t('message_error_min_char_phone')),
          state: yup.string().required(t('message_error_required_state')),
          city: yup.string().required(t('message_error_required_city')),
          dealerId: yup.string().required(t('message_error_required_dealerId')),
          message: yup.string().required(t('message_error_required_message')),
          agreement: yup.boolean().oneOf([true], '')
        };
      case 'Recall':
        return {
          name: yup.string().required(t('message_error_required_name')).min(4, t('message_error_min_char_name')),
          surname: yup.string().required(t('message_error_required_surname')),
          email: yup.string().required(t('message_error_required_email')).email(t('message_error_invalid_email')),
          document: yup
            .string()
            .required(t('message_error_required_document'))
            .min(5, t('message_error_min_char_document')),
          telephone: yup
            .string()
            .required(t('message_error_required_phone'))
            .min(11, t('message_error_min_char_phone')),
          model: yup.string().required(t('message_error_required_model')),
          VIN: yup.string().required(t('message_error_required_VIN')).min(10, t('message_error_min_char_VIN')),
          state: yup.string().required(t('message_error_required_state')),
          city: yup.string().required(t('message_error_required_city')),
          dealerId: yup.string().required(t('message_error_required_dealerId')),
          agreement: yup.boolean().oneOf([true], t('message_error_required_agreement'))
        };
    }
  };

  const validationSchemaConfig = () => {
    switch (locale) {
      case 'es-ar':
        return {
          name: yup.string().required(t('message_error_required_name')).min(4, t('message_error_min_char_name')),
          message: yup
            .string()
            .min(5, t('message_error_min_char_message'))
            .required(t('message_error_required_message')),
          email: yup.string().required(t('message_error_required_email')).email(t('message_error_invalid_email')),
          telephone: yup
            .string()
            .required(t('message_error_required_phone'))
            .min(14, t('message_error_min_char_phone')),
          agreement: yup.boolean().oneOf([true], t('message_error_required_agreement'))
        };
      case 'pt-br':
        return {
          name: yup.string().required(t('message_error_required_name')).min(4, t('message_error_min_char_name')),
          message: yup
            .string()
            .min(5, t('message_error_min_char_message'))
            .required(t('message_error_required_message')),
          email: yup.string().required(t('message_error_required_email')).email(t('message_error_invalid_email')),
          telephone: yup
            .string()
            .required(t('message_error_required_phone'))
            .min(14, t('message_error_min_char_phone')),
          cpf: yup
            .string()
            .required(t('message_error_required_cpf'))
            .test('test-invalid-cpf', t('message_error_invalid_cpf'), (cpf) => isValidCPF(cpf))
            .min(14, t('message_error_invalid_cpf')),
          agreement: yup.boolean().oneOf([true], t('message_error_required_agreement'))
        };
      case 'es-ve':
        return getSchemaConfigByType();
    }
  };

  const validationSchema = yup.object().shape(validationSchemaConfig());

  const {
    register,
    handleSubmit,
    formState,
    setValue,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    reValidateMode: 'onChange'
  });

  const watchedState = watch('state');

  useLayoutEffect(() => {
    if (contactFormType === 'Recall' && isTDV && router?.query?.chassi) {
      setValue('VIN', router.query.chassi);
    }
  }, []);

  if (contactFormType === 'Presupuesto' && router.query.model) {
    setValue('model', router.query.model);
  }

  const resetForm = useCallback(() => {
    reset();
  }, [reset]);

  const [isStatusComponent, setIsStatusComponent] = useState('default');
  const [isDisable, setIsDisable] = useState(false);

  function onBackForm() {
    setIsStatusComponent('default');
  }

  function loadCities(selectedState) {
    const filtered = dealers?.filter((state) => {
      return state?.address?.state.toUpperCase() === selectedState?.toUpperCase();
    });
    const resultCities = [...new Set(filtered.map((city) => city.address.city.toUpperCase()))].sort();

    setListCities(resultCities);
  }

  function loadDealers(selectedCity) {
    const filtered = dealers?.filter((dealer) => {
      return dealer?.address?.city.toUpperCase() === selectedCity?.toUpperCase();
    });
    setListDealers(filtered);
  }

  function onChangeState(e) {
    loadCities(e.target.value);
    setValue('city', 'placeholder');
    setValue('dealerId', 'placeholder');
  }

  function onChangeCity(e) {
    loadDealers(e.target.value);
    setValue('dealerId', 'placeholder');
  }

  function getType() {
    switch (contactFormType) {
      case 'Presupuesto':
        return 'BUDGET';
      case 'Atención al cliente':
        return 'CUSTOMER_SUPPORT';
      case 'Recall':
        return 'RECALL';
      case 'SAC ':
        return 'SAC';
      case 'TOYOTA_TOUR':
        return 'TOYOTA_TOUR';
    }
  }

  const onSubmit = async (data) => {
    setIsDisable(true);
    data.locale = locale;
    data.type = getType();

    const captchaPayload = await captchaToken('CONTACT', publicRuntimeConfig?.G_RECAPTCHA_TOKEN || '');
    if (!captchaPayload) {
      console.debug('Falhou verificação do recaptcha');
      setIsStatusComponent('error');

      return false;
    }

    if (isTDV) {
      data.phone = removeSpecialCharacters(data?.telephone).replace(/\s+/g, '');
      delete data?.telephone;
    }

    if (contactFormType === 'Atención al cliente') {
      data.comment = data?.message;
      delete data?.message;
    }

    data.captchaPayload = captchaPayload;

    const opts = {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    };
    const url = '/api/contact';

    fetch(url, opts)
      .then((res) => {
        if (res.status === 200) {
          setIsDisable(false);
          resetForm(reset);
          setIsStatusComponent('success');

          return;
        }
        setIsDisable(false);
        setIsStatusComponent('error');
      })
      .catch((err) => {
        setIsDisable(false);
        console.log(err);
      });
  };

  function onError(error) {
    console.log('Error: ', error);
  }

  return (
    <div className={`${css['molecule__contact-form-container']} ${className}`} {...other}>
      <Wrapper spacing={headerSpacing}>
        <Section className={css['success-container']}>
          <Script
            src={`https://www.google.com/recaptcha/enterprise.js?render=${publicRuntimeConfig.G_RECAPTCHA_TOKEN}`}
          ></Script>

          {isStatusComponent === 'success' && (
            <SuccessSubmitForm
              onBackForm={onBackForm}
              titleSuccess={titleSuccess ? titleSuccess : t('success_title')}
              contentSuccess={contentSuccess ? contentSuccess : t('success_content')}
              contentBackForm={t('content_back_form')}
              contentBackHome={t('content_back_home')}
            />
          )}

          {isStatusComponent === 'error' && (
            <SuccessSubmitForm
              onBackForm={onBackForm}
              titleSuccess={titleError ? titleError : t('error_title')}
              contentSuccess={contentError ? contentError : t('error_content')}
              contentBackForm={t('content_back_form')}
              contentBackHome={t('content_back_home')}
            />
          )}
          {isStatusComponent === 'default' && (
            <>
              <SectionHeader
                title={title ? title : t('title_form')}
                description={{
                  value: description ? description : t('description_form'),
                  textColor: 'gray-4'
                }}
                image={
                  isTDV
                    ? null
                    : {
                        src: '/images/contact-form-header.png',
                        alt: ''
                      }
                }
              />
              <form
                onSubmit={handleSubmit(onSubmit, onError)}
                className={isTDV ? `${css['form-container__section-TDV']}` : `${css['form-container__section']}`}
              >
                {contactFormType === 'Presupuesto' && (
                  <div className={css['form-container__section']} data-grid-full-width data-grid-list="3">
                    <Title className={css['form-container__title']} variant={5} data-grid-full-width>
                      {t('select_model_interest')}
                    </Title>
                    <FormSelect
                      className={css['form-container__input']}
                      id="model"
                      placeholder={t('placeholder_model_interest')}
                      dataRegister={register('model')}
                      displayLabel={false}
                      dataErrors={errors['model']}
                    >
                      {models &&
                        models?.map((model, index) => {
                          return (
                            <option key={index} value={model.name}>
                              {model.name}
                            </option>
                          );
                        })}
                    </FormSelect>
                  </div>
                )}
                <div className={css['form-container__section']} data-grid-list={isTDV ? '3' : '2'}>
                  {isTDV && (
                    <Title className={css['form-container__title']} variant={5} data-grid-full-width>
                      {t('title_section_one')}
                    </Title>
                  )}
                  <FormInput
                    id="name"
                    className={css['form-container__input']}
                    label={t('label_user_name_field')}
                    placeholder={t('placeholder_user_name_field')}
                    dataRegister={register('name')}
                    dataErrors={errors['name']}
                    maxLength={isTDV && '40'}
                    masking={(event) => {
                      const value = event.target.value;
                      event.target.value = isTDV ? removeSpecialCharactersAndNumbers(value) : value;
                    }}
                    type="text"
                  />

                  {isTDV && (
                    <>
                      <FormInput
                        id="surname"
                        className={css['form-container__input']}
                        label={t('label_user_surname_field')}
                        placeholder={t('placeholder_user_surname_field')}
                        dataRegister={register('surname')}
                        maxLength="80"
                        dataErrors={errors['surname']}
                        masking={(event) => {
                          const value = event.target.value;
                          event.target.value = removeSpecialCharactersAndNumbers(value);
                        }}
                        type="text"
                      />
                      <FormInput
                        id="document"
                        className={css['form-container__input']}
                        label={t('label_document_field')}
                        placeholder={t('placeholder_document_field')}
                        dataRegister={register('document')}
                        maxLength="17"
                        dataErrors={errors['document']}
                        type="text"
                      />
                    </>
                  )}

                  <FormInput
                    id="email"
                    className={css['form-container__input']}
                    label={t('label_email_field')}
                    dataRegister={register('email')}
                    dataErrors={errors['email']}
                    type="text"
                    maxLength={isTDV && '200'}
                    placeholder={t('placeholder_email_field')}
                  />

                  <FormInput
                    id="telephone"
                    className={css['form-container__input']}
                    label={t('label_phone_field')}
                    dataRegister={register('telephone')}
                    dataErrors={errors['telephone']}
                    type="tel" // This presents only a numerical keyboard on mobile versions;
                    maxLength={t('max_lenght_phone')}
                    placeholder={t('placeholder_phone_field')}
                    masking={(event) => {
                      const value = event.target.value;
                      event.target.value = isTDB ? phoneMask(value) : isTDV ? phoneMaskTDV(value) : value;
                    }}
                  />

                  {!isTDV && (
                    <FormSelect
                      id="subject"
                      className={css['form-container__input']}
                      placeholder={t('placeholder_subject')}
                      label={t('label_subject')}
                      dataRegister={register('subject')}
                      dataErrors={errors['subject']}
                    >
                      <option value={t('label_praise')}>{t('label_praise')}</option>
                      <option value={t('label_information')}>{t('label_information')}</option>
                      <option value={t('label_suggestion')}>{t('label_suggestion')}</option>
                      <option value={t('label_complaint')}>{t('label_complaint')}</option>
                    </FormSelect>
                  )}

                  {isTDB && (
                    <>
                      <FormInput
                        id="cpf"
                        className={css['form-container__input']}
                        label={t('label_cpf')}
                        placeholder={t('placeholder_cpf')}
                        type="text"
                        dataRegister={register('cpf')}
                        dataErrors={errors['cpf']}
                        maxLength="14"
                        masking={(event) => {
                          const value = event.target.value;
                          event.target.value = cpfMask(value);
                        }}
                      />

                      <FormInput
                        id="frame"
                        className={css['form-container__input']}
                        label={t('label_chassi')}
                        placeholder={t('placeholder_chassi')}
                        type="text"
                        dataRegister={register('chassi')}
                        dataErrors={errors['chassi']}
                        maxLength="21"
                        masking={(event) => {
                          const value = event.target.value;
                          event.target.value = chassiMask(value);
                        }}
                      />
                    </>
                  )}

                  {contactFormType === 'Recall' && (
                    <div
                      className={isTDV ? `${css['form-container__section']}` : null}
                      data-grid-full-width
                      data-grid-list="3"
                    >
                      <Title className={css['form-container__title']} variant={5} data-grid-full-width>
                        {t('title_section_three')}
                      </Title>
                      <FormInput
                        id="model"
                        className={css['form-container__input']}
                        label={t('label_model_field')}
                        dataRegister={register('model')}
                        dataErrors={errors['model']}
                        type="text"
                        maxLength="80"
                        placeholder={t('placeholder_model_field')}
                      />
                      <FormInput
                        id="VIN"
                        className={css['form-container__input']}
                        label={t('label_VIN_field')}
                        dataRegister={register('VIN')}
                        dataErrors={errors['VIN']}
                        type="text"
                        maxLength="20"
                        placeholder={t('placeholder_VIN_field')}
                      />
                    </div>
                  )}
                </div>
                {isTDV && (
                  <div
                    className={isTDV ? `${css['form-container__section']}` : null}
                    data-grid-full-width
                    data-grid-list="3"
                  >
                    <Title className={css['form-container__title']} variant={5} data-grid-full-width>
                      {t('title_section_two')}
                    </Title>
                    <FormSelect
                      className={css['form-container__input']}
                      id="state"
                      label={t('label_state_field')}
                      placeholder={t('placeholder_state_field')}
                      onChange={onChangeState}
                      dataRegister={register('state')}
                      dataErrors={errors['state']}
                    >
                      {states &&
                        states?.map((state, index) => {
                          return (
                            <option key={index} value={state}>
                              {state}
                            </option>
                          );
                        })}
                    </FormSelect>
                    <FormSelect
                      className={css['form-container__input']}
                      id="city"
                      label={t('label_city_field')}
                      placeholder={t('placeholder_city_field')}
                      onChange={onChangeCity}
                      dataRegister={register('city')}
                      dataErrors={errors['city']}
                    >
                      {watchedState &&
                        listCities?.map((city, index) => {
                          return (
                            <option key={index} value={city}>
                              {city}
                            </option>
                          );
                        })}
                    </FormSelect>
                    <FormSelect
                      className={css['form-container__input']}
                      id="dealerId"
                      label={t('label_dealerId_field')}
                      placeholder={t('placeholder_dealerId_field')}
                      dataRegister={register('dealerId')}
                      dataErrors={errors['dealerId']}
                    >
                      {listDealers &&
                        listDealers?.map((dealer, index) => {
                          return (
                            <option key={index} value={dealer.id}>
                              {dealer.name}
                            </option>
                          );
                        })}
                    </FormSelect>
                  </div>
                )}

                {(!isTDV || contactFormType === 'Atención al cliente') && (
                  <div data-grid-full-width>
                    <FormTextarea
                      id="message"
                      className={css['form-container__input']}
                      placeholder={t('placeholder_message')}
                      label={t('label_message')}
                      maxLength={isTDV ? '1000' : null}
                      dataRegister={register('message')}
                      dataErrors={errors['message']}
                    />
                  </div>
                )}

                <div data-grid-full-width>
                  {!isTDV && <ContentDivider size="small" className={css['form-divider']} />}

                  <FormCheckbox
                    displayError={false}
                    id="agreement"
                    dataRegister={register('agreement')}
                    dataErrors={errors['agreement']}
                    className={css['input-last-container']}
                  >
                    {t('label_agreement')}&nbsp;
                    <AnchorLink
                      size={isTDV ? 'medium' : null}
                      link={t('link_agreement')}
                      hasArrow={false}
                      color={isTDV ? 'base-black' : 'gray-4'}
                      linkLabel={t('link_label_agreement')}
                    />
                  </FormCheckbox>
                </div>
                <div data-grid-full-width className={css['form-submit-container']}>
                  <Button type="submit" disabled={!formState.isValid || isDisable}>
                    {t('button_submit_form')}
                  </Button>
                </div>
              </form>
            </>
          )}
        </Section>
      </Wrapper>
    </div>
  );
}

export default ContactForm;
