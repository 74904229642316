import { getCurrencySymbol } from ".";

export const formatPriceAppointment = (price, locale, bonusText) => {
  if (price === 0 && bonusText) {
    return bonusText;
  }

  const currencySymbol = getCurrencySymbol(locale);

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencySymbol
  });

  const formattedPrice = formatter.format(price);
  return `${formattedPrice}¹`;
};
