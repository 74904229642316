import React from 'react';

import ActionNoticeApps from '../action-notice-apps';
import ContentDivider from 'components/atoms/content-divider';
import Button from 'components/atoms/button';
import IconArrow from 'components/atoms/icon-arrow';
import IconHeart from 'components/atoms/icon-heart';
import { IconCheck, IconShare, IconWhatsApp } from 'components/icons';
import Title from 'components/atoms/title';
import { isNumberMask } from 'utils/forms/';

import { phoneMask, phoneMaskDdg, replaceUrl } from 'utils/validations';
import { urlHttpCheck } from 'utils/functions';
import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule InfoDealer
 */

function InfoDealer(props) {
  const t = i18n.useTranslations('components.info-dealer');

  const {
    className = '',
    dealer = {},
    listServices = [],
    locale,
    handleInfoDealer,
    urlWhats = t('url_whatsapp'),
    textWhats = t('text_whatsapp'),
    enableScheduleService,
    handleFavoriteChange,
    onConfirmAppointment = null,
    selectedDealerId = null,
    serviceAppointmentContentData = null,
    typeInfo = '',
    ...other
  } = props;

  const isSelected = dealer.id === selectedDealerId;
  const availableButtonConfirmAppointment = typeof onConfirmAppointment === 'function' && dealer?.id;
  const showRoomHours = dealer?.showRoomWeekDaysHour || dealer?.hfShowRoomSaturdayHour || dealer?.hfShowRoomSundayHour;
  const isContentBlockedValue = isContentBlocked(dealer, serviceAppointmentContentData);
  const isNotTypeAppointment = typeInfo !== 'appointment';

  const isTDB = locale?.toLowerCase() === 'pt-br';
  const isTASA = locale?.toLowerCase() === 'es-ar';
  const isTDV = locale?.toLowerCase() === 'es-ve';

  const socialMedias = [];

  dealer?.dealerProducts?.forEach((product) => {
    if (product.attributes && product.attributes.socialMedia) {
      product.attributes.socialMedia.forEach((social) => {
        socialMedias.push(social);
      });
    }
  });

  const getSocialIcon = (type) => {
    const iconSrc = `/images/social-media-icons/${type}.svg`;
    const altText = `${type.charAt(0).toUpperCase() + type.slice(1)} Icon`;
    return <img src={iconSrc} alt={altText} />;
  };

  function isContentBlocked(dealer, serviceAppointmentContentData) {
    if (!dealer?.availableForAppointment && serviceAppointmentContentData?.isAppointmentStep) {
      return true;
    }
    return false;
  }

  function capitalizeWords(string) {
    if (typeof string === 'string') {
      let words = string.split(' ');
      let result = '';
      words.map((word) => {
        let w = word.charAt(0).toUpperCase() + word.toLowerCase()?.substring(1);
        result = result + ' ' + w;
      });
      return result.substring(1);
    }
  }

  function phoneMaskCondition(phone) {
    return phone.substring(0, 4) == '0800' ? phoneMaskDdg(phone) : phoneMask(phone);
  }

  function PhoneNumbersList() {
    const numbersArray = dealer?.phone.split(';');

    return numbersArray.map((number, index) => <p key={index}>{number}</p>);
  }

  return (
    <div className={`${css['molecule__info-dealer-container']} ${className}`} data-type-info={typeInfo} {...other}>
      {isContentBlockedValue ? (
        <div className={`${css['info-dealer']} ${css['blocked']}`}>
          <div className={css['info-dealer-header']}>
            <Title className={css['info-dealer-title-blocked']} variant={4} level={3}>
              {capitalizeWords(dealer?.name)}
            </Title>
          </div>
          {(isTASA || isTDV) && (
            <div className={css['info-dealer-address-blocked']}>
              {dealer?.companyName && <p>Razón Social: {dealer?.companyName}</p>}
              {dealer?.idDealerTasa && <p>CUIT: {dealer?.idDealerTasa}</p>}
              <p>
                {dealer?.address?.street && dealer?.address?.street}{' '}
                {dealer?.address?.number && dealer?.address?.number.concat(' -')}{' '}
                {dealer?.address?.city &&
                  dealer?.address?.city?.[0] &&
                  dealer?.address?.city?.[0]?.toUpperCase() +
                    dealer?.address?.city?.toLowerCase()?.substring(1)?.concat(',')}{' '}
              </p>
              <p>
                {dealer?.address?.state &&
                  dealer?.address?.state?.[0] &&
                  dealer?.address?.state?.[0]?.toUpperCase() + dealer?.address?.state?.toLowerCase()?.substring(1)}
              </p>
            </div>
          )}

          <ContentDivider size="tiny" />

          <ActionNoticeApps
            className={css['info-dealer-notice-blocked']}
            messageContent={serviceAppointmentContentData?.messageInstallApps}
            linksApp={serviceAppointmentContentData?.linksApp}
          />
        </div>
      ) : (
        <>
          <div className={css['info-dealer']}>
            <div className={css['info-dealer-header']}>
              <Title variant={4} level={3} color="primary">
                {capitalizeWords(dealer?.name)}
              </Title>

              {!!handleFavoriteChange && (
                <button type="button" className={css['btn-favorite']} onClick={() => handleFavoriteChange(dealer)}>
                  <IconHeart filled={dealer.isFavorite} />
                </button>
              )}
            </div>
            {availableButtonConfirmAppointment && serviceAppointmentContentData?.isAppointmentStep && (
              <Button
                className={css['confirm-button']}
                color={isSelected ? 'primary' : 'ghost-2'}
                onClick={() => onConfirmAppointment(dealer?.id)}
              >
                {isSelected ? (
                  <>
                    {t('label_selected_dealer')}
                    <IconCheck className={css['button-icon']} />
                  </>
                ) : (
                  t('label_select_dealer')
                )}
              </Button>
            )}
            <div className={css['info-dealer-container']}>
              <div className={css['info-dealer-container__address']}>
                {isTDB && (
                  <>
                    <p>
                      {dealer?.address?.street && dealer?.address?.street.concat(',')}{' '}
                      {dealer?.address?.number && dealer?.address?.number.concat(',')}{' '}
                      {dealer?.address?.neighborhood && dealer?.address?.neighborhood.concat(',')}
                    </p>
                    <p>
                      {dealer?.address?.city && dealer?.address?.city.concat(',')}{' '}
                      {dealer?.address?.uf && dealer?.address?.uf.concat(',')} CEP{' '}
                      {dealer?.address?.zipcode && dealer?.address?.zipcode}
                    </p>
                  </>
                )}
                {(isTASA || isTDV) && (
                  <>
                    {dealer?.companyName && <p>Razón Social: {dealer?.companyName}</p>}
                    {dealer?.idDealerTasa && <p>CUIT: {dealer?.idDealerTasa}</p>}
                    <p>
                      {dealer?.address?.street && dealer?.address?.street}{' '}
                      {dealer?.address?.number && dealer?.address?.number.concat(' -')}{' '}
                      {dealer?.address?.city &&
                        dealer?.address?.city?.[0] &&
                        dealer?.address?.city?.[0]?.toUpperCase() +
                          dealer?.address?.city?.toLowerCase()?.substring(1)?.concat(',')}{' '}
                    </p>
                    <p>
                      {dealer?.address?.state &&
                        dealer?.address?.state?.[0] &&
                        dealer?.address?.state?.[0]?.toUpperCase() +
                          dealer?.address?.state?.toLowerCase()?.substring(1)}
                    </p>
                  </>
                )}
                {dealer?.contactEmail && (
                  <p className={css['email-address-container']}>
                    <a
                      className={css['info-dealer-container__address__email']}
                      href={`mailto:${dealer?.contactEmail}`}
                      title={t('title-email')}
                    >
                      {dealer?.contactEmail}
                    </a>
                  </p>
                )}
                {dealer?.site && (
                  <a
                    className={css['info-dealer-container__address__website']}
                    target="_blank"
                    href={urlHttpCheck(dealer?.site)}
                  >
                    <span>{replaceUrl(dealer?.site)}</span>
                    <IconShare />
                  </a>
                )}
                {(dealer?.phone || dealer?.whatsapp) && (
                  <div className={css['info-dealer-container__address__phones']}>
                    {dealer?.phone &&
                      ((isTDB && <p>{phoneMaskCondition(dealer?.phone)}</p>) ||
                        ((isTASA || isTDV) && PhoneNumbersList()))}
                    {dealer?.whatsapp && (
                      <p className={css['info-dealer-container__address__cell-whatsap']}>
                        <span>
                          {isTDB && phoneMaskCondition(dealer?.whatsapp)}
                          {(isTASA || isTDV) && dealer?.whatsapp}
                        </span>
                        <a
                          href={urlWhats.concat(isNumberMask(dealer?.whatsapp)).concat(textWhats)}
                          aria-label="Whatsapp"
                          title="Whatsapp"
                        >
                          <IconWhatsApp />
                        </a>
                      </p>
                    )}
                  </div>
                )}

                {isTDV && (
                  <div className={css['info-dealer-container__media']}>
                    {socialMedias.map((social, index) => (
                      <a key={index} target="_blank" href={urlHttpCheck(social?.value)}>
                        {getSocialIcon(social.name)}
                      </a>
                    ))}
                  </div>
                )}
              </div>
              {handleInfoDealer && isNotTypeAppointment && (
                <div className={css['info-dealer-container__button']}>
                  <Button color="light" className={css['button']} onClick={handleInfoDealer}>
                    {t('button_see_map')}
                  </Button>
                </div>
              )}
            </div>
          </div>

          {(isTASA || isTDV) && showRoomHours && (
            <>
              <ContentDivider size="tiny" />
              <div className={css['info-dealer-container__address']}>
                <p>{dealer?.showRoomWeekDaysHour}</p>
                <p>{dealer?.hfShowRoomSaturdayHour}</p>
                <p>{dealer?.hfShowRoomSundayHour}</p>
              </div>
            </>
          )}

          {!!listServices?.length && (
            <>
              <ContentDivider size="tiny" />
              <div className={css['info-services']}>
                <div className={css['info-services__container-list']}>
                  <Title level={3} className={css['info-services__container-list__title']}>
                    {t('services_offered')}
                  </Title>
                  <div className={css['services-slider-container']}>
                    {dealer?.services?.map((item, index) => {
                      return (
                        <div key={index} className={css['info-services__items-services']}>
                          {item?.content?.icon?.url && (
                            <span className={css['info-services__items-services__icon']}>
                              <img src={item?.content?.icon?.url} alt={item?.content?.icon?.title} />
                            </span>
                          )}
                          <span className={css['info-services__items-services__content']}>{item?.title}</span>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {!!enableScheduleService && dealer?.whatsapp && (
                  <div className={css['info-services__button-services']}>
                    <Button link={urlWhats.concat(dealer?.whatsapp).concat(textWhats)} className={css['button']}>
                      <span>{t('schedule_service')}</span>
                      <IconArrow size="small" arrowPosition="right" />
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
}

export default InfoDealer;
