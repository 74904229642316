export const news = [
  {
    date: '22/10/2024',
    category: 'Institucional',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus fermentum odio ac arcu malesua.',
    media: {
      url: 'https://media.toyota.com.ar/611daa4a-45c1-478f-96e1-c938b5ca9a9e.png'
    },
    // eslint-disable-next-line max-len
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed faucibus justo ac urna molestie, ut finibus metus aliquam. Vivamus fermentum odio at arcu malesuad.',
    link: '/news1',
    linkLabel: 'Continuar lendo'
  },
  {
    date: '22/10/2024',
    category: 'Institucional',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus fermentum odio ac arcu malesua.',
    media: {
      url: 'https://media.toyota.com.ar/611daa4a-45c1-478f-96e1-c938b5ca9a9e.png'
    },
    // eslint-disable-next-line max-len
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed faucibus justo ac urna molestie, ut finibus metus aliquam. Vivamus fermentum odio at arcu malesuad.',
    link: '/news1',
    linkLabel: 'Continuar lendo'
  },
  {
    date: '22/10/2024',
    category: 'Institucional',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus fermentum odio ac arcu malesua.',
    media: {
      url: 'https://media.toyota.com.ar/611daa4a-45c1-478f-96e1-c938b5ca9a9e.png'
    },
    // eslint-disable-next-line max-len
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed faucibus justo ac urna molestie, ut finibus metus aliquam. Vivamus fermentum odio at arcu malesuad.',
    link: '/news1',
    linkLabel: 'Continuar lendo'
  },
  {
    date: '22/10/2024',
    category: 'Institucional',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus fermentum odio ac arcu malesua.',
    media: {
      url: 'https://media.toyota.com.ar/611daa4a-45c1-478f-96e1-c938b5ca9a9e.png'
    },
    // eslint-disable-next-line max-len
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed faucibus justo ac urna molestie, ut finibus metus aliquam. Vivamus fermentum odio at arcu malesuad.',
    link: '/news1',
    linkLabel: 'Continuar lendo'
  },
  {
    date: '22/10/2024',
    category: 'Institucional',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus fermentum odio ac arcu malesua.',
    media: {
      url: 'https://media.toyota.com.ar/611daa4a-45c1-478f-96e1-c938b5ca9a9e.png'
    },
    // eslint-disable-next-line max-len
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed faucibus justo ac urna molestie, ut finibus metus aliquam. Vivamus fermentum odio at arcu malesuad.',
    link: '/news1',
    linkLabel: 'Continuar lendo'
  },
  {
    date: '22/10/2024',
    category: 'Institucional',
    title: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus fermentum odio ac arcu malesua.',
    media: {
      url: 'https://media.toyota.com.ar/611daa4a-45c1-478f-96e1-c938b5ca9a9e.png'
    },
    // eslint-disable-next-line max-len
    content: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed faucibus justo ac urna molestie, ut finibus metus aliquam. Vivamus fermentum odio at arcu malesuad.',
    link: '/news1',
    linkLabel: 'Continuar lendo'
  }
];
export const settings = {
  isUppercase: false,
  removeCarousel: true,
};
