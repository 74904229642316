import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';

import Vehicle from 'utils/repo/vehicle';
import Dealers from 'utils/repo/dealers';

class ContactForm implements ContentBlock {
  label = 'Contact Form';

  component = 'ContactForm';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'title',
      label: 'Title form',
      help: 'The title of the form',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'description',
      label: 'Description',
      help: 'Is the description of the form',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'titleSuccess',
      label: 'Title success',
      help: 'The title used in the sent form success response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'contentSuccess',
      label: 'Content success',
      help: 'Descriptive text used in the sent form success response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'titleError',
      label: 'Title error',
      help: 'The title used in the sent form error response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'contentError',
      label: 'Content error',
      help: 'Descriptive text used in the sent form error response modal',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    },
    {
      name: 'headerSpacing',
      label: 'Header spacing',
      help: 'Is the spacing between the header with title and description of the form',
      type: 'Number',
      control: 'input:number',
      defaultValue: '0'
    },
    {
      name: 'contactFormType',
      label: 'Contact Form Type (required)',
      help: '',
      type: 'String',
      required: true,
      options: function (tenant) {
        if (tenant?.locale === 'es-ve') {
          return ['Presupuesto', 'Atención al cliente', 'Recall'];
        } else {
          return ['SAC', 'TOYOTA_TOUR'];
        }
      },
      control: 'select',
    },
  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    const locale = extra?.context?.locale;
    const productId = extra?.context?.productId;
    const models = [];

    const vehiclesByModels =
    (await dataFetcher(Vehicle.getAllVehicles(), { locale: locale }))?.data?.vehicles || [];

    const dealersByLocation =
    (await dataFetcher(Dealers?.getAllDealersEssential(), { productId: productId }))?.data?.dealers || [];

    for (const model of vehiclesByModels) {
      models.push({
        name: model.name || '',
        vehicleExternalId: model?.vehicleExternalId || '',
        version: model?.mainVersion?.name || '',
        prodYear: model?.mainVersion?.modelYear?.year || '',
        katashiki: model?.mainVersion?.modelYear?.katashiki || '',
        suffixCode: model?.mainVersion?.modelYear?.suffixCode || '',
        price: model?.mainVersion?.modelYear?.vehicleColors?.[0]?.price,
        internalColor: {
          name: model?.mainVersion?.modelYear?.defaultInternalColorCode || '',
          code: model?.mainVersion?.modelYear?.defaultInternalColorCode || ''
        },
        externalColor: {
          name: model?.mainVersion?.modelYear?.vehicleColors?.[0]?.color?.name || '',
          code: model?.mainVersion?.modelYear?.vehicleColors?.[0]?.color?.code || ''
        }
      });
    }

    const states = [...new Set(dealersByLocation.map((state) => state.address.state))].sort()

    return {
      locale: String(locale).toLowerCase(),
      title: extra?.settings?.title,
      description: extra?.settings?.description,
      titleSuccess: extra?.settings?.titleSuccess,
      contentSuccess: extra?.settings?.contentSuccess,
      titleError: extra?.settings?.titleError,
      contentError: extra?.settings?.contentError,
      headerSpacing: extra?.settings?.headerSpacing || '0',
      contactFormType: extra?.settings?.contactFormType,
      models: models,
      dealers: dealersByLocation,
      states: states
    };
  }
}

export { ContactForm };
