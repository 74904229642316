import { ContentBlock } from 'components/content-blocks/ContentBlock';
import { ContentBlockType } from 'components/content-blocks/ContentBlockType';
import { anchor } from 'utils/meta';
import VehicleQuery from 'utils/repo/vehicle';

class TabsWrapper implements ContentBlock {
  label = 'Car Models Tabs';

  component = 'TabsWrapper';

  icon = 'GenericIcon';

  type = ContentBlockType.PASSIVE;

  multiple = false;

  settings = [
    anchor,
    {
      name: 'disclaimer',
      label: 'Disclaimer',
      help: 'Legal text that will be below the component',
      type: 'String',
      control: 'input:text',
      defaultValue: ''
    }
  ];

  properties = [];

  async adapter(contents: any[], extra: any, dataFetcher: any): Promise<object> {
    let getVehiclesByCategoryData;

    try {
      getVehiclesByCategoryData = await dataFetcher(await VehicleQuery.getVehiclesByCategoryForDisplay(), {
        locale: extra?.context?.locale
      });

      return {
        carCategoriesData: getVehiclesByCategoryData?.data?.vehicleCategories,
        locale: extra?.context?.locale,
        title: extra?.section?.name
      };
    } catch (err) {
      console.error('Falha ao buscar dados do componente TabsWrapper', err);
    }

    return { carCategoriesData: [] };
  }
}

export { TabsWrapper };
