import React, { useEffect, useRef, useState } from 'react';
import i18n from 'utils/i18n';
import { removeDuplicateItems } from 'utils/forms';

import FormInput from 'components/atoms/form-input';
import SubmitSearchButton from 'components/atoms/submit-search-button';
import SearchResultList from 'components/organisms/search-result-list';
import Loader from 'components/atoms/loader';
import Wrapper from 'components/atoms/wrapper';
import Section from 'components/utils/section';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

// loading the sass style fot the component
import css from './styles.module.scss';
import { news } from '../../../utils/__mocks__/NewsMock';
/**
 * Organism Search
 *
 * Search
 */

function Search(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.search');

  const {
    className = '',
    children,
    titleNoResults,
    textNoResults,
    spacingTop = 0,
    spacingBottom = 0,
    bgColor = 'default',
    locale,
    type = 'default',
    ...other
  } = props;
  const [token, setToken] = useState('');
  const [tokenNameList, setTokenNameList] = useState('');
  const [dataResultListSearch, setDataResultListSearch] = useState([]);
  const [statusSearch, setStatusSearch] = useState('default');
  const [loading, setLoading] = useState(false);

  const validationSchemaConfig = {
    search: yup
      .string()
      .test('is-no-empty', t('message_error_min_char_search'), (value) => {
        return value.trim() !== '';
      })
  };

  const validationSchema = yup.object().shape(validationSchemaConfig);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      search: ''
    }
  });

  const tokenEmpty = token.trim() === '';

  const watchSearch = watch('search', '');

  const prevTokenRef = useRef('');

  useEffect(() => {
    setToken(watchSearch);
  }, [watchSearch]);

  const onSubmit = (data) => {
    const newToken = data.search;
    setToken(newToken);

    if (tokenEmpty) {
      return setDataResultListSearch([]);
    }

    if (newToken === prevTokenRef.current) {
      return;
    }

    setLoading(true);

    fetch(`/api/search?token=${token}`)
      .then(async (result) => {
        let resultData = await result.json();
        if (resultData) {
          const newResultData = removeDuplicateItems(resultData?.searchByToken);
          setDataResultListSearch(newResultData);
          setTokenNameList(token);
        }
        setTimeout(() => {
          setStatusSearch('showListSearch');
          setLoading(false);
        }, 1000);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
    prevTokenRef.current = newToken;
  };

  const onSubmitNews = (data) => {
    //TO DO WITH THE NEWS API
    const newToken = data.search;
    setToken(newToken);
    setDataResultListSearch(news);
    setTokenNameList(token);
    setStatusSearch('showListSearch');
  };

const onError = (error) => {
  console.log('Error: ', error);
};

const handleCloseClick = () => {
  setValue('search', '');
};

  return (
    <Wrapper className={`${css['organism__search-container']} ${className}`} {...other}>
      <Section>
        <div className={`${css['form']}`}>
        <form
          className={css['search-form']}
          onSubmit={type === 'default' ? handleSubmit(onSubmit, onError) : handleSubmit(onSubmitNews, onError)}
        >
          <FormInput
            displayLabel={false}
            id="search"
            type="search"
            label={t('search-label')}
            placeholder={t('search-placeholder')}
            className={css['search-form__input']}
            dataRegister={register('search')}
            dataErrors={errors['search']}
          />
          <SubmitSearchButton />
        </form>
        <button
            className={watchSearch === '' ? css['close-icon-empty'] : css['close-icon']}
            onClick={() => handleCloseClick()}
          ></button>
                  </div>
        {loading ? (
          <div className={css['loader-container']}>
            <Loader />
          </div>
        ) : (
          <>
            {statusSearch === 'showListSearch' && type === 'default' && (
              <SearchResultList
                data={dataResultListSearch}
                titleNoResults={titleNoResults}
                textNoResults={textNoResults}
                bgColor={bgColor}
                spacingTop={spacingTop}
                spacingBottom={spacingBottom}
                token={tokenNameList}
              />
            )}
            {statusSearch === 'showListSearch' && type === 'news' && (
              <SearchResultList
                type="news"
                data={dataResultListSearch}
                titleNoResults={titleNoResults}
                textNoResults={textNoResults}
                bgColor={bgColor}
                spacingTop={spacingTop}
                spacingBottom={spacingBottom}
                token={tokenNameList}
              />
            )}
          </>
        )}
      </Section>
    </Wrapper>
  );
}

export default Search;
