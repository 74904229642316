/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import GalleryModal from 'components/molecules/gallery-modal';
import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Organism Gallery
 *
 * <!-- TODO: add a description here! -->
 */
function Gallery(props) {
  // const gt = i18n.useTranslations('global');
  const t = i18n.useTranslations('components.gallery');

  const {
    className = "",
    galleryData = [],
    coverImage,
    title,
    ...other
  } = props;

  const [clickedImage, setClickedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if(galleryData) {
      try {
        setClickedImage(galleryData[0].url);
      } catch (error) {
        console.log("Error: ", error);
      }
    }
  }, []);

  const handleClick = () => {
    setOpenModal(true);
  };

  const handleNextImage = () => {
    const totalLenght = galleryData.lenght;
    if(currentIndex + 1 >= totalLenght) {
      setCurrentIndex(0);
      const newUrl = galleryData[0].url;
      setClickedImage(newUrl);
      return;
    }
    const newIndex = currentIndex + 1;
    const newUrl = galleryData.filter((item) => {
      return galleryData.indexOf(item) === newIndex;
    });
    const newItem = newUrl[0].url;
    setClickedImage(newItem);
    setCurrentIndex(newIndex);
  };

  return <div
    className={`${css["organism__gallery-container"]} ${className}`}
    {...other}
  >
    <button onClick={() => handleClick()} className={css['gallery-button']}>
      <span className={css['title-sup']}>{t("title")}</span>
      <span className={css['title-inf']}>{title}</span>
      <img src={coverImage} alt="interior" className={css['gallery-button__image']}/>
    </button>

    {openModal && (
      <GalleryModal
        dataGallery={galleryData}
        clickedImage={clickedImage}
        handleNextImage={handleNextImage}
        setClickedImage={setClickedImage}
        setOpenModal={setOpenModal}
      />
    )}
  </div>;
}

export default Gallery;
