export function getStatesFromDealers(dealers) {
  if (!dealers) {
    return false;
  }

  return [...new Set(dealers.map((state) => state.address.state))].sort();
}

export function getDealersFromState(dealers, state) {
  if (!dealers && !state) {
    return false;
  }

  return dealers.filter((dealer) => {
    return dealer.address.state.toUpperCase() == state.toUpperCase();
  });
}

export function getCitiesThatHaveDealersFromState(state) {
  if (!state) {
    return false;
  }

  return [...new Set(state.map((city) => city.address.city.toUpperCase()))].sort();
}

export function validatePhoneNumber(phoneNumber) {
  if (typeof phoneNumber === 'string' && phoneNumber.trim().length > 0) {
    phoneNumber = phoneNumber.replace(/\s+/g, '').replace(/[^\d-()]/g, '');

    if (!/^[\d()-]+$/.test(phoneNumber)) {
      return false;
    }

    return true;
  }
  return true;
}

export function validateName(name) {
  const regexNameValid = /^[a-zA-ZáàâãéèêíïóôõöúçñÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ\s]+$/;
  return regexNameValid.test(name);
}

export const removeDuplicateItems = (list) => {
  const uniqueList = [];
  const uniqueKeys = new Set();

  for (const item of list) {
    const key = JSON.stringify(item);
    if (!uniqueKeys.has(key)) {
      uniqueKeys.add(key);
      uniqueList.push(item);
    }
  }

  return uniqueList;
};

export const validationFullNameField = /^[a-zA-ZÀ-ÖØ-öø-ÿ]+(?: [a-zA-ZÀ-ÖØ-öø-ÿ]+)+$/;

export function areaCodeMask(code) {
  code = code.replace(/\D/g, '');
  code = code.slice(0, 4);

  if (code.length >= 2) {
    return `(${code})`;
  } else {
    return code;
  }
}

export function updateURLParameter(parameter, value) {
  const url = new URL(window.location.href);
  const params = new URLSearchParams(url.search);
  params.set(parameter, value);
  url.search = params.toString();

  const newUrl = url.pathname + url.search;
  window.history.pushState({ path: newUrl }, '', newUrl);
}

export function isTextMask(value) {
  return value?.replace(/\d/g, '');
}

export function isNumberMask(value) {
  return value?.replace(/\D/g, '');
}

export const isNumber = /^\d+$/;

export const isValidDNI = /^\d+(\.\d+)*$/;

export const isValidDate = /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/;

export function validatePhoneMobileTasa(phoneNumber) {
  if (phoneNumber) {
    phoneNumber = phoneNumber.replace(/\D/g, '');

    if (!/^\d+$/.test(phoneNumber)) {
      return false;
    }

    if (phoneNumber.startsWith('0') || phoneNumber.startsWith('15')) {
      return false;
    }

    // Check for no more than 5 identical consecutive numbers
    if (/(\d)\1{4,}/.test(phoneNumber)) {
      return false;
    }

    return phoneNumber;
  }
  return true;
}

export function removeSpecialCharacters(text) {
  var regex = /[^0-9a-zA-Z\s]/g;
  return text.replace(regex, '');
}

export function isOver18Years(birthday) {
  const currentDate = new Date();
  const birthDate = new Date(birthday);

  const age = currentDate.getFullYear() - birthDate.getFullYear();
  const isOver18 =
    age > 18 ||
    (age === 18 && birthDate.getMonth() < currentDate.getMonth()) ||
    (age === 18 && birthDate.getMonth() === currentDate.getMonth() && birthDate.getDate() <= currentDate.getDate());

  return isOver18;
}

export function updatePageState(pageState, router) {
  const queryString = router.query.state ? { state: router.query.state } : {};

  if (router.query.state !== pageState) {
    queryString.state = pageState;
  }

  router.push(
    {
      pathname: `/${router?.query?.slug.join('/')}`,
      query: queryString
    },
    undefined,
    { shallow: true }
  );
}

export function updatePageParam(param, data, router, shouldRender = true) {
  const queryString = router.query[param] ? { [param]: router.query[param] } : {};

  if (router.query[param] !== data) {
    queryString[param] = data;
  }

  router.push(
    {
      pathname: `/${router?.query?.slug?.join('/') || ''}`,
      query: queryString
    },
    undefined,
    { shallow: shouldRender }
  );
}

export function isBirthdayBeforeToday(birthday) {
  const currentDate = new Date();
  const maxAllowedAge = 123;

  if (birthday && typeof birthday === 'string' && birthday.length >= 10) {
    const birthDateParts = birthday?.split('-')?.map(Number);

    const birthDate = new Date(birthDateParts[0], birthDateParts[1] - 1, birthDateParts[2]);

    // Set the time of birthDate to 23:59:59 to include the current day
    birthDate.setHours(23, 59, 59, 999);

    // Compare only the dates, ignoring the hours
    birthDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);

    const ageDifferenceInMilliseconds = currentDate - birthDate;
    const ageDifferenceInYears = ageDifferenceInMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Consider leap years

    //Check if the age is less than or equal to 123 years AND the birthdate is before the current date
    return ageDifferenceInYears <= maxAllowedAge && birthDate < currentDate;
  }
  return true;
}

export function mapLocaleToCountry(locale) {
  switch (locale) {
    case 'es-ar':
      return 'Argentina';
    case 'pt-br':
      return 'Brasil';
    default:
      return '';
  }
}

export const sendRequest = async (method, url, data) => {
  const methodsWithBody = [
    'POST',
    'PUT',
    'DELETE'
  ];
  const opts = {
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  if (methodsWithBody.includes(method)) {
    opts.body = JSON.stringify(data);
  }

  return await fetch(url, opts);
};

export function getCitiesFromState(statesAndCities, state) {
  if (!statesAndCities && !state) {
    return false;
  }

    const selectedState = statesAndCities.filter(data => data.name.toUpperCase() === state.toUpperCase());

    return [...new Set(selectedState[0].cities.map((city) => city.name.toUpperCase()))].sort();

}

export function removeCityAndStateParamsFromURL() {
  const url = new URL(window.location.href);
  url.searchParams.delete('city');
  url.searchParams.delete('state');
  window.history.replaceState({}, document.title, url.toString());
}

export function phoneNoDddTasaMask(value) {
  return value
  .replace(/^[0]/g, '')
  .replace(/15\d+?$/g, '');
}

export function removeSpecialCharactersAndNumbers(text){
  let string = "";
  let smallLetters = "abcdefghijklmnopqrstuvwxyzáàâãéèêíïóôõöúçñ";
  let capitalLetters = "ABCDEFGHIJKLMNOPQRSTUVWXYZÁÀÂÃÉÈÊÍÏÓÔÕÖÚÇÑ";
  let space = " ";
  for (let i = 0; i < text.length; i++){
    if(smallLetters.includes(text[i]) || capitalLetters.includes(text[i]) || space.includes(text[i])){
      string += text[i];
    }
  }
  return string.replace(/[0-9]/g, '');
}

export function phoneNoZeroTasaMask(value) {
  return value
  .replace(/^[0]/g, '');
}
