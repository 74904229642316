import React, { useState } from 'react';
import CloseButton from 'components/atoms/close-button';
// import i18n from 'utils/i18n';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule GalleryModal
 *
 * <!-- TODO: add a description here! -->
 */
function GalleryModal(props) {
  // const gt = i18n.useTranslations('global');
  // const t = i18n.useTranslations('components.gallery-modal');

  const {
    className = "",
    dataGallery = null,
    setOpenModal = null,
    ...other
  } = props;

  const [currentImage, setCurrentImage] = useState(0);

  const handleNextImage = () => {
    if (setCurrentImage) {
      if (currentImage + 1 == dataGallery.length) {
        setCurrentImage(0);
        return;
      }
      setCurrentImage(currentImage + 1);
    }
  };

  const handlePreviousImage = () => {
    if (setCurrentImage) {
      if (currentImage == 0) {
        setCurrentImage(dataGallery.length - 1);
        return;
      }
      setCurrentImage(currentImage - 1);
    }
  };

  const handleClose = (e) => {
    if (setOpenModal) {
      if (e.target.classList.contains("dismiss")) {
        setOpenModal(false);
      }
    }
  };

  const imageHandler = () => {
    if(dataGallery) {
      if (dataGallery[currentImage].url) {
        if (dataGallery[currentImage].alt) {
          return <img src={dataGallery[currentImage].url} alt={dataGallery[currentImage].alt} className={css['image-over']} />;
        }
        // eslint-disable-next-line jsx-a11y/img-redundant-alt
        return <img src={dataGallery[currentImage].url} alt='gallery image' className={css['image-over']} />;
      }
    }
  };

  // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
  return <div
    className={`${css["molecule__gallery-modal-container"]} ${className} dismiss`}
    onClick={handleClose}
    {...other}
  >
    <div className={`${css["gallery-wrapper"]} dismiss`}>
      {imageHandler()}
      <CloseButton color="white" className={`${css["close-button"]} dismiss`} onClick={handleClose} />
      <button className={css["arrowButtonPrev"]} onClick={handlePreviousImage}>
        <div className={css["arrow-left"]} />
      </button>
      <button className={css["arrowButtonNext"]} onClick={handleNextImage}>
        <div className={css["arrow-right"]} />
      </button>
    </div>
  </div>;
}

export default GalleryModal;
