import React, { useEffect } from 'react';
import CloseButton from 'components/atoms/close-button';
// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule SimpleModal
 *
 * <!-- TODO: add a description here! -->
 */
const SimpleModal = ({ isOpen, onClose, disabledClose = false, children, className = '', ...other }) => {
  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('keydown', handleEscape);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
    };
  }, [isOpen, onClose]);

  return isOpen ? (
    <div className={`${css['modal-overlay']}`}>
      <div
        className={`${css['molecule__simple-modal-container']} ${className}`}
        role="dialog"
        aria-modal="true"
        tabIndex={-1}
        {...other}
      >
        <div className={css['modal-close']}>
          <CloseButton color="black" onClick={onClose} disabled={disabledClose} />
        </div>

        <div className={css['modal-content']}>{children}</div>
      </div>
    </div>
  ) : (
    <div></div>
  );
};
export default SimpleModal;
