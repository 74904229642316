/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
import { File } from 'components/icons';
import { dateBr2Iso } from 'utils/functions';
import Button from 'components/atoms/button';
import IconDownload from 'components/atoms/icon-download';
import React, { useEffect, useState } from 'react';
import i18n from 'utils/i18n';
import { generateCertificate } from './generate-cetificate';

// loading the sass style fot the component
import css from './styles.module.scss';

/**
 * Molecule ServiceRecall
 *
 * <!-- TODO: add a description here! -->
 */
function ServiceRecall(props) {
  const gt = i18n.useTranslations('global');
  const messages = i18n.useTranslations('components.service_recall');

  const { className = '', data = {}, locale, cardVariation = 'false', setContactModalIsOpen = () => undefined } = props;

  const [newRepairDate, setNewRepairDate] = useState('');

  const isTDB = locale === 'pt-BR';
  const isTASA = locale === 'es-AR';

  function handleCertificate() {
    const newData = {
      ...data,
      newRepairDate
    };
    generateCertificate(newData);
  }

  useEffect(() => {
    data?.repairDate &&
      setNewRepairDate(
        gt('shortDate', { value: new Date(dateBr2Iso(data?.repairDate)) }) +
          ' ' +
          gt('shortTime', { value: new Date(dateBr2Iso(data?.repairDate)) })
      );
  }, [data?.repairDate]);

  return (
    <div className={`${css['service-recall-container']} ${className}`}>
      <ul
        className={css[ isTDB ? 'content-list': 'content-list_ar' ]}
        data-is-card-variation={cardVariation}
      >
        {!cardVariation && (
          <>
            <li className={css['content-list__item']}>
              <span className={css['content-list__label']}>{messages('model')}</span>
              <strong className={css['content-list__value']}>{data?.model}</strong>
            </li>
            {data?.modelYear && (
              <li className={css['content-list__item']}>
                <span className={css['content-list__label']}>{messages('manufacture_year')}</span>
                <strong className={css['content-list__value']}>
                  {`${messages('from')} ${data?.modelYear?.replace('/', `${messages('to')}`)}`}
                </strong>
              </li>
            )}
          </>
        )}
        <li className={css[isTDB ? 'content-list__item' : 'content-list__item__recall']}>
          <span className={css['content-list__label']}>Recall</span>
          <strong className={css['content-list__value']}>{data?.name}</strong>
        </li>

        {cardVariation && (
          <>
            <li className={css['content-list__item']}>
              <span className={css['content-list__label']}>Status</span>
              <strong className={css['content-list__value']} data-status={data?.repairStatus}>
                {data?.repairStatus === 'DONE'
                  ? messages('status_done')
                  : data?.repairStatus === 'PENDING' && messages('status_pending')}
              </strong>
              <span className={css['content-list__value']}>{data?.dealership?.name}</span>
              <strong className={css['content-list__value']}>{newRepairDate}</strong>
            </li>
            {data?.repairStatus === 'PENDING' && (
              <>
                <li className={css['content-list__item']}>
                  <Button onClick={() => setContactModalIsOpen(true)}>{messages('contact_button_label')}</Button>
                </li>
                <li className={css['content-list__item']}>
                  <Button link={`/contato/localize-uma-concessionaria`}>{messages('schedule_button_label')}</Button>
                </li>
              </>
            )}
          </>
        )}

        {(data?.medias?.[0]?.url || data?.repairStatus === 'DONE' || data?.file?.url) && isTASA && (
          <li className={css['content-list__item']}>
            <span className={css['content-list__label']}>Detalle</span>
            <ul className={css['links-container']}>
              {data?.repairStatus === 'DONE' && (
                <li>
                  <button onClick={() => handleCertificate()} className={css['warning-container']}>
                    <File fill="#EB0A1E" className={css['warning-container_icon']} />
                    <strong className={css['warning-container__text']}>{messages('certificate')}</strong>
                  </button>
                </li>
              )}
              {(data?.medias?.[0]?.url || data?.file?.url) && (
                <li>
                  <a
                    href={data?.file?.url ? data?.file?.url : data?.medias?.[0]?.url}
                    className={css['warning-container']}
                  >
                    <IconDownload size={'large'} color="primary" />
                    <strong className={css['warning-container__text']}>{messages('warning')}</strong>
                  </a>
                </li>
              )}
            </ul>
          </li>
        )}
      </ul>

      {(data?.medias?.[0]?.url || data?.repairStatus === 'DONE' || data?.file?.url) && isTDB && (
        <ul className={css['links-container']}>
          {data?.repairStatus === 'DONE' && (
            <li>
              <button onClick={() => handleCertificate()} className={css['warning-container']}>
                <File fill="#EB0A1E" className={css['warning-container_icon']} />
                <strong className={css['warning-container__text']}>{messages('certificate')}</strong>
              </button>
            </li>
          )}
          {(data?.medias?.[0]?.url || data?.file?.url) && (
            <li>
              <a href={data?.file?.url ? data?.file?.url : data?.medias?.[0]?.url} className={css['warning-container']}>
                <IconDownload size={'large'} color="primary" />
                <strong className={css['warning-container__text']}>{messages('warning')}</strong>
              </a>
            </li>
          )}
        </ul>
      )}
    </div>
  );
}

export default ServiceRecall;
